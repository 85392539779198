<template>
  <div class="pop_container">
    <div class="title_area">SELECT DESTINATION</div>
    <div class="body_area">
      <div class="form_section_generate_slip">
        <table>
          <tr>
            <td>
              <select v-model="dropdown.type" @change="fetchDropdownData">
                <option value="hub">Hub</option>
                <option value="branch">Branch</option>
                <option value="name">Name</option>
                <option value="address">Address</option>
              </select>
               <select v-model="dropdown.name" v-if="dropdown.type == 'hub'">
                <option value="">Select hub</option>
                <option v-for="(hub,i) in hubs" :key="'hubs_'+i" :value="hub.name">
                  {{hub.name}}
                </option>
              </select>
              <select v-model="dropdown.name" v-else-if="dropdown.type == 'branch'">
                <option value="">Please select a branch</option>
                <option v-for="(branch,i) in branches" :key="'branches_'+i" :value="branch.name">
                  {{branch.name}}
                </option>
              </select>
              <span class="dynamic_data_container" v-else-if="dropdown.type == 'name'">
                <input type="text" v-model="dropdown.name" class="dynamic_data_trigger" @focus="show_name = true" @blur="hideDropdown('show_name')"/>
                <span class="dynamic_data_wrapper" v-if="show_name">
                  <span 
                    v-for="(name,i) in filterNames" 
                    :key="'receiver_address'+i"
                    @click="dropdown.name = name"
                    >
                    {{name}}
                  </span>
                </span>
              </span>
              <span class="dynamic_data_container" v-else-if="dropdown.type == 'address'">
                <input type="text" v-model="dropdown.name" class="dynamic_data_trigger" @focus="show_address = true" @blur="hideDropdown('show_address')"/>
                <span class="dynamic_data_wrapper" v-if="show_address">
                  <span 
                    v-for="(name,i) in filterAddress" 
                    :key="'receiver_address'+i"
                    @click="dropdown.name = name"
                    >
                    {{name}}
                  </span>
                </span>
              </span>
            </td>
          </tr>
        </table>
        <div class="pop_buttons_container">
          <a class="tr_cancel" @click="closeModal">Cancel</a>
          <a class="tr_save" @click="applyFilter">Save</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "popSelectService",
  created(){
    this.setDefaultValues();
    this.fetchDropdownData(false);
  },
  computed:{
    filterNames(){
      return this.users_list.filter(element => {
        if(element.toLowerCase().includes(this.dropdown.name.toLowerCase())){
          return element;
        }
      }).slice(0,4);
    },
    filterAddress(){
      return this.address_list.filter(element => {
        if(element.toLowerCase().includes(this.dropdown.name.toLowerCase())){
          return element;
        }
      }).slice(0,4);
    }
  },
  data(){
    return {
      dropdown:{
        type:"hub",
        name:""
      },
      hubs:[],
      branches:[],
      users_list:[],
      address_list:[],
      show_name:false,
      show_address:false
    };
  },
  methods: {
    closeModal() {
      this.modalStatus = !this.modalStatus;
      this.$emit("closeModal", this.modalStatus);
    },
    applyFilter(){
      if(!this.dropdown.type || !this.dropdown.name){
        this.toast.error("Please select a destination");
      }else{
        this.$emit("appliedFilter",this.dropdown);
        this.closeModal();
      }
    },
    fetchDropdownData(resetname = true){
      this.hubs = [];
      this.branches = [];
      if(resetname){
        this.dropdown.name = "";
      }
      if(this.dropdown.type == 'hub'){
        this.fetchHubs()
      }else if(this.dropdown.type == 'branch'){
        this.fetchBranches();
      }else if(this.dropdown.type == 'name'){
        this.fetchUsers()
      }else if(this.dropdown.type == 'address'){
        this.fetchAddresses();
      }
    },
    setDefaultValues(){
      if(this.dropdownValues){
        this.dropdown.type = this.dropdownValues.type || "hub";
        this.dropdown.name = this.dropdownValues.name || "";
      }
    },
    fetchHubs(){
      this.axios.get("api/division/exceptCurrent")
                .then(response => {
                  this.hubs = response.data.divisions;
                })
                .catch(error => {
                  console.log(error);
                });
    },
    fetchBranches(){
      this.axios.get("api/branch/view")
                .then(response => {
                  this.branches = response.data.branches;
                })
                .catch(error => {
                  console.log(error);
                });
    },
    fetchUsers(){
      this.axios.post("api/booking/name/list")
        .then(response => {
          this.users_list = response.data.name;
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchAddresses(){
      this.axios.post("api/booking/address/list")
        .then(response => {
          this.address_list = response.data.address;
        })
        .catch(error => {
          console.log(error);
        });
    },
    hideDropdown(type){
      setTimeout(() => {
        this[type] = false;
      },200);
    },
  },
  props:["dropdownValues"]
};
</script>
<style scoped>
.dynamic_data_container{
  position: relative;
}
.dynamic_data_wrapper{
  background:rgb(230, 230, 230);
  display:flex;
  flex-direction: column;
  position:absolute;
  width:200px;
  text-align: initial;
  left:0;
  right:0;
}
.dynamic_data_wrapper span{
  border-bottom:1px solid rgb(177, 177, 177);
  padding:0.5rem;
  font-size:0.9rem;
  color:rgb(51, 51, 51);
}
.dynamic_data_wrapper span:hover{
  background:black;
  color:white;
  border-bottom:1px solid white;
}
.dynamic_data_trigger{
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFW…UGSC0G4qlAnA3E4VeuXFmFogibf6A2xaA7j6CNgzPlAAQYAFCUljt4v5gNAAAAAElFTkSuQmCC) no-repeat;
    background-position: right 10px top 50%;
    border: 1px solid #ccc;
    color: #333;
    height: 40px;
    width: 200px!important;
    padding: 2px 10px;
    display: inline-block;
    margin-right: 25px;
}
</style>