<template>
  <div class="transmit_sub_container">
    <div class="transmittal_slip_filter">
      <label>Enter Barcode</label>
      <input type="text" v-model="search" @keyup.enter="fetchCourier"/>
      <input type="button" value="Submit" @click="fetchCourier"/>
    </div>
    <div v-if="couriers.length > 0">
      <section>
        <div class="form_section_transmit">
          <table class="transmit_table1">
            <tr>
              <td>USER BRANCH</td>
              <td>SUMMARY TRANSMIT SLIP</td>
              <td>
                <img src="../../assets/temp_logo1.png" />
              </td>
            </tr>
          </table>
        </div>
      </section>
      <section>
        <div class="form_section_transmit">
          <table class="transmit_table2">
            <tr>
              <td>
                <div class="service_name">
                  <div class="label_and_element_wrapper">
                    <select v-model="filter.provider_id" @change="fetchServices">
                      <option value="">Select Provider</option>
                      <option v-for="(provider,i) in providers" :key="'provider_dropdown'+i" :value="provider.id">
                        {{provider.name}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="courier_name">
                  <div class="label_and_element_wrapper">
                    <select v-model="filter.service_id" :disabled="services.length == 0">
                      <option value="">Select Service</option>
                      <option :value="service.id" v-for="(service,i) in services" :key="'service_dropdown'+i">
                        {{service.name}}
                      </option>
                    </select>
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <p v-if="filter.type && filter.name">{{filter.name}}</p>
                  <a class="edit" @click="showpopSelectDestination = true">SELECT DESTINATION</a>
                </div>
              </td>
              <td>
                <div class="dispatch_date">
                  Dispatch date:
                  <span>{{moment(Date.now()).format("DD-MM-YYYY")}}</span>
                </div>
                <div class="bar_text"></div>
              </td>
            </tr>
          </table>
        </div>
      </section>
      <section>
        <div class="form_section_transmit">
          <table class="transmit_table3">
            <tr>
              <td>REF</td>
              <td>ADDRESSEE</td>
              <td>ITEM</td>
              <td>ITEM DESCRIPTION</td>
              <td>SENDER</td>
              <td>ACTION</td>
            </tr>
            <tr v-for="(courier,i) in couriers" :key="'courier_'+i">
              <td>
                <barcode :value="courier.booking_prefix + courier.booking_id" tag="img"/>
              </td>
              <td>
                <p v-if="courier.delivery_name">{{courier.delivery_name}}</p>
                <p v-if="courier.delivery_address_1">{{courier.delivery_address_1}}</p>
                <p v-if="courier.delivery_address_2">{{courier.delivery_address_2}}</p>
                <p v-if="courier.delivery_city">{{courier.delivery_city}}</p>
                <p v-if="courier.delivery_state">{{courier.delivery_state}}</p>
                <p v-if="courier.delivery_country">{{courier.delivery_country}}</p>
              </td>
              <td>{{courier.item_qty}}</td>
              <td>{{courier.goods_description || 'NA'}}</td>
              <td>{{courier.pickup_name}}</td>
              <td>
                <a class="delete_icon" @click="couriers.splice(i,1)"></a>
              </td>
            </tr>
          </table>
        </div>
      </section>
      <section class="centered" v-if="filter.type && filter.name">
        <a class="link_bt bt_cancel" @click="$router.push('/pages/dashboard')">Cancel</a>
        <a class="link_bt bt_save" @click="createHubSlip">Generate Slip</a>
      </section>
      <div class="black-overlay" v-if="showpopSelectDestination">
        <div class="box-modal smallPops">
          <div class="body">
            <popSelectDestination
              v-show="showpopSelectDestination"
              @closeModal="showpopSelectDestination = false"
              @appliedFilter="filterCouriers"
              :dropdownValues="filter"
            />
          </div>
        </div>
      </div>
    </div>
    <p v-if="show_error && couriers.length == 0">No matching couriers found</p>
  </div>
</template>
<script>
import popSelectDestination from "./popups/popSelectDestination.vue";
export default {
  name: "x",
  components: {
    popSelectDestination
  },
  created(){
    this.$emit("breadCrumbData",["Hub Dispatch","Generate Slip Scan"]);
    this.fetchProviders();
  },
  data() {
    return {
      search:"",
      isDisabled: false,
      showpopSelectDestination: false,
      couriers:[],
      filter:{
        type:"",
        date:"",
        name:"",
        service_id:"",
        provider_id:"",
        bookings:[]
      },
      providers:[],
      services:[],
      show_error:false
    };
  },
  methods:{
    fetchCourier(){
      if(this.search){
        this.axios.get("api/courierbooking/fetchbookingiddetails/"+this.search)
                .then(response => {
                  if(response.data.booking_details){
                    if(this.couriers.find(element => response.data.booking_details.booking_id == element.booking_id)){
                      this.toast.error("Courier already scanned");
                    }else{
                      this.couriers.push(response.data.booking_details);
                      this.search = "";
                    }
                  }else{
                    this.toast.error("Courier not found");
                  }
                })
                .catch(error => {
                  console.log(error);
                });
      }else{
        this.toast.error("The Barcode field cannot be empty.");
      }
      
    },
    fetchProviders(){
      this.axios.get("api/provider")
                .then(response => {
                  this.providers = response.data.providers;
                })
                .catch(error => {
                  console.log(error);
                });
    },
    fetchServices(){
      this.services = [];
      this.axios.get("api/provider/services/"+this.filter.provider_id)
                .then(response => {
                  this.services = response.data.services;
                })
                .catch(error => {
                  console.log(error);
                });    
    },
    filterCouriers(data){
      this.filter.type = data.type;
      this.filter.name = data.name;
      this.filter.bookings = this.couriers.map(element => {
        return element.booking_id;
      });
      this.fetchCouriers();
    },
    fetchCouriers(){
      this.show_error = false;
      this.axios.post("api/hub/slip/view",this.filter)
                .then(response => {
                  this.couriers = response.data.list;
                  if(this.couriers.length == 0){
                    this.show_error = true;
                    this.filter.type = "";
                    this.filter.name = ""
                  }
                })
                .catch(error => {
                  console.log(error);
                });
    },
    createHubSlip(){
      this.axios.post("api/hub/slip",this.filter)
                .then(response => {
                  this.toast.success(response.data.msg);
                  this.$router.push("/pages/ViewTransmittalSlip");
                })
                .catch(error => {
                  console.log(error);
                });
    }
  }
};
</script>
<style scoped>
.delete_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../../assets/ico_delete.png) no-repeat;
  background-size: cover;
  margin-right: 5px;
}
</style>